exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-example-media-jsx": () => import("./../../../src/pages/example-media.jsx" /* webpackChunkName: "component---src-pages-example-media-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-healthcare-agedcare-jsx": () => import("./../../../src/pages/industries/healthcare/agedcare.jsx" /* webpackChunkName: "component---src-pages-industries-healthcare-agedcare-jsx" */),
  "component---src-pages-industries-healthcare-edicu-jsx": () => import("./../../../src/pages/industries/healthcare/edicu.jsx" /* webpackChunkName: "component---src-pages-industries-healthcare-edicu-jsx" */),
  "component---src-pages-industries-healthcare-index-jsx": () => import("./../../../src/pages/industries/healthcare/index.jsx" /* webpackChunkName: "component---src-pages-industries-healthcare-index-jsx" */),
  "component---src-pages-industries-healthcare-radiology-jsx": () => import("./../../../src/pages/industries/healthcare/radiology.jsx" /* webpackChunkName: "component---src-pages-industries-healthcare-radiology-jsx" */),
  "component---src-pages-industries-others-jsx": () => import("./../../../src/pages/industries/others.jsx" /* webpackChunkName: "component---src-pages-industries-others-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-product-rosterlab-ai-jsx": () => import("./../../../src/pages/product/rosterlab-ai.jsx" /* webpackChunkName: "component---src-pages-product-rosterlab-ai-jsx" */),
  "component---src-pages-product-rosterlab-free-jsx": () => import("./../../../src/pages/product/rosterlab-free.jsx" /* webpackChunkName: "component---src-pages-product-rosterlab-free-jsx" */),
  "component---src-pages-product-self-scheduling-jsx": () => import("./../../../src/pages/product/self-scheduling.jsx" /* webpackChunkName: "component---src-pages-product-self-scheduling-jsx" */),
  "component---src-pages-schedge-jsx": () => import("./../../../src/pages/schedge.jsx" /* webpackChunkName: "component---src-pages-schedge-jsx" */),
  "component---src-pages-studies-jsx": () => import("./../../../src/pages/studies.jsx" /* webpackChunkName: "component---src-pages-studies-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

