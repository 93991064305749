/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onRouteUpdate = function () {
  // Don't track while developing.
  if (typeof window.fbq === `function`) {
    window.fbq("track", "ViewContent")
  }
}

export const onClientEntry = () => {}

// This is added so that the default smooth scroll animation is disabled
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
}) => {
  const { pathname } = location
  const scrollToTopRoutes = [
    "/",
    "/product",
    "/industries",
    "/pricing",
    "/blog",
    "/study",
  ]
  // if the new route is part of the list above, scroll to top (0, 0)

  // check if blog article path
  const blogRegex = /^\/blog\/.+\/$/
  const caseStudyRegex = /^\/studies\/.+\/$/
  if (blogRegex.test(pathname)) {
    if (prevRouterProps && blogRegex.test(prevRouterProps.location.pathname)) {
      console.log("BLOG ARTICLE")
      return true
    }
  }

  // check if case studies article path
  if (caseStudyRegex.test(pathname)) {
    if (
      prevRouterProps &&
      caseStudyRegex.test(prevRouterProps.location.pathname)
    ) {
      console.log("CASE STUDIES ARTICLE")
      return true
    }
  }

  const instantScroll = scrollToTopRoutes.some(route =>
    pathname.startsWith(route),
  )

  if (instantScroll) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    })
  }

  return false
}
